import React, { useEffect, useState } from "react";
import { Contact } from "./Contact";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_HOST } from "../config/config";
import { setChatToken } from "../redux_slices/providerSlice";
import { StreamChat } from "stream-chat";
import "./ContactTable2.css";
import {
  Chat,
  Channel,
  ChannelList,
  ChannelHeader,
  MessageList,
  MessageInput,
  Window,
} from "stream-chat-react";

const ContactsTable = ({ isOpenCasesWindow, title }) => {
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const [channel, setChannel] = useState(null);
  const [client, setClient] = useState(null);
  const [dummy, setDummy] = useState(0);
  const [activeContactChat, setActiveContactChat] = useState(null);
  const provider = useSelector((state) => state.provider);
  const user = useSelector((state) => state.user);
  const apiKey = "tpt3e9cy94h9";
  const authToken = localStorage.getItem("authToken");

  const handleContactUpdate = () => {
    setDummy(dummy + 1);
  };

  const handleChatWithMe = (id) => {
    console.log("chat id clicked is " + id);
    if (activeContactChat === id) setActiveContactChat(null);
    else {
      setActiveContactChat("messaging:" + id);
    }
  };

  const fetchContacts = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.get(`${API_HOST}/leads`, {
        params: {
          providerId: provider.providerId,
          providerUserId: user.userId,
        },
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.data) setContacts(response.data.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const initClient = async () => {
    if (provider && !provider.chatToken) {
      const response = await fetch(
        `${API_HOST}/chat/createProviderChatAccount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            providerId: provider.providerId,
            userId: user.userId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        dispatch(setChatToken(data.data));
      }
    }
    if (provider && provider.chatToken && !client) {
      console.log("connecting user ! ");
      const chatClient = StreamChat.getInstance(apiKey);
      await chatClient.connectUser(
        {
          id: provider.providerId,
          image: provider.img,
          name: provider.firstName,
        },
        provider.chatToken
      );

      setClient(chatClient);
    }
  };
  const fetchChannels = async () => {
    if (client && activeContactChat) {
      const filters = { cid: activeContactChat };
      const sort = { last_message_at: -1 };
      const options = { limit: 10 };

      const channels = await client.queryChannels(filters, sort, options);
      if (channels && channels.length > 0) {
        const activeChannel = channels[0];
        await activeChannel.watch();
        setChannel(activeChannel);
      }
    }
  };

  useEffect(() => {
    fetchChannels();
    if (!client) initClient();
  }, [user, provider, client, activeContactChat, client, activeContactChat]);

  useEffect(() => {
    if (provider && (!contacts || contacts.length === 0)) fetchContacts();
  }, [provider]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {contacts &&
        contacts.map((contact) => {
          if (
            (isOpenCasesWindow && !contact.filled) ||
            !contact.amount ||
            contact.amount === 0 ||
            (!isOpenCasesWindow && contact.filled && contact.amount > 0)
          ) {
            return (
              <div key={contact.id} style={{ width: "80%", margin: "0 auto" }}>
                <Contact
                  contact={contact}
                  handleChatWithMe={handleChatWithMe}
                  handleContactUpdate={() => handleContactUpdate()}
                />

                {client &&
                  activeContactChat &&
                  activeContactChat === "messaging:" + contact.chatId &&
                  provider &&
                  provider.providerId && (
                    <Chat
                      client={client}
                      theme="messaging light"
                      className="flex-1 overflow-hidden"                      
                    >
                      <div className="flex flex-row h-96 w-full border border-gray-300">
                        {/* Chat Window */}
                        <div dir="rtl" className="flex-1 h-full flex flex-col border-r border-gray-300">
                          {channel && (
                            <Channel>
                              <Window>
                                <ChannelHeader />

                                {/* Message List (Auto-scrolls & fills space) */}
                                <div className="flex-1 min-h-0 overflow-y-auto p-2 bg-gray-100">
                                  <MessageList autoScrollToBottom="smooth" />
                                </div>

                                {/* Message Input (Always visible at bottom) */}
                                <div className="border-t border-gray-300 p-2 sticky bottom-0 bg-white">
                                  <MessageInput />
                                </div>
                              </Window>
                            </Channel>
                          )}
                        </div>

                        {/* Channel List (Moved to Right) */}
                        <div dir="rtl" className="w-1/6 h-full overflow-y-auto bg-gray-50">
                          <ChannelList
                            filters={{ cid: activeContactChat }}
                            sort={{ last_message_at: -1 }}
                            options={{ limit: 10 }}
                            allowNewMessagesFromUnfilteredChannels={false}
                          />
                        </div>
                      </div>
                    </Chat>
                  )}
              </div>
            );
          }
          return <div />;
        })}
    </div>
  );
};
export default ContactsTable;
