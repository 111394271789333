import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  ChannelList,
  ChannelHeader,
  MessageList,
  MessageInput,
  Window,
} from "stream-chat-react";
import "../contacts/ContactTable2.css";
import { setChatToken } from "../redux_slices/providerSlice";
import { API_HOST } from "../config/config";

const Messages = ({ requId }) => {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [channel, setChannel] = useState(null);
  const [requests, setRequests] = useState([]);
  const [selectedRequestId, setSelectedRequestId] = useState(requId);
  const user = useSelector((state) => state.user);
  const provider = useSelector((state) => state.provider);
  const apiKey = "tpt3e9cy94h9";
  const authToken = localStorage.getItem("authToken");

  
  useEffect(() => {
    const initClient = async () => {
      if (provider && !provider.chatToken) {
        const response = await fetch(`${API_HOST}/chat/createProviderChatAccount`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: user.userId,
            providerId: provider.providerId,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          dispatch(setChatToken(data.data));
        }
      }
      if (user && user.chatToken && !client) {
        console.log("connecting user ! ");
        const chatClient = StreamChat.getInstance(apiKey);
        await chatClient.connectUser(
          {
            id: provider.providerId,
            image: provider.img,
            name: user.firstName,
          },
          provider.chatToken
        );

        setClient(chatClient);
      }
    };

    initClient();

    return () => {
      if (client) {
        client.disconnectUser();
      }
    };
  }, [user, client]);

  useEffect(() => {
    const fetchChannels = async () => {
      if (client && selectedRequestId) {
        const sort = { last_message_at: -1 };
        const options = { limit: 10 };
        const filters={ type: "messaging", members: { $in: [provider.providerId]}}

        const channels = await client.queryChannels(filters, sort, options);
        if (channels && channels.length > 0) {
          const activeChannel = channels[0];
          await activeChannel.watch();
          setChannel(activeChannel);
        }
      }
    };

    fetchChannels();
  }, [client, selectedRequestId]);

  if (!client) return <div>Loading...</div>;

  return (
    <div className="flex flex-col h-screen w-full overflow-hidden" dir="rtl">
      {/* Dropdown Section */}
      
      {/* Chat Section */}
      <Chat
        client={client}
        theme="messaging light"
        className="flex-1 overflow-hidden"
      >
        <div className="flex flex-row h-full w-full">
          {/* Chat Window */}
          <div className="flex-1 h-full flex flex-col">
            {channel && (
              <Channel>
                <Window>
                  <ChannelHeader />

                  {/* Message List (Auto-scrolls & fills space) */}
                  <div className="flex-1 min-h-0 overflow-y-auto">
                    <MessageList autoScrollToBottom="smooth" />
                  </div>

                  {/* Message Input (Always visible at bottom) */}
                  <div className="border-t border-gray-300 p-2 sticky bottom-0 bg-white">
                    <MessageInput />
                  </div>
                </Window>
              </Channel>
            )}
          </div>

          {/* Channel List (Moved to Right) */}
          <div className="w-1/4 border-l border-gray-300 h-full overflow-y-auto order-first">
            <ChannelList
                filters={{ type: "messaging", members: { $in: [provider.providerId]}}}
              sort={{ last_message_at: -1 }}
              options={{ limit: 10 }}
              allowNewMessagesFromUnfilteredChannels={false}
            />
          </div>
        </div>
      </Chat>
    </div>
  );
};

export default Messages;
